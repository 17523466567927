import React, { useState, useEffect } from "react";
import "./questionscreen2.css";
import Localstorage from "../utils/Localstorage";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Questions } from "../../Redux/Actioncreator";
import Questionsapi from "../../APIS/QuestionsAPI";
import useLogout from "../utils/Idle";
import axios from "axios";
import Answersposting from "../../APIS/Answersposting";
import LoadingOverlay from "react-loading-overlay";

function Questionscreen2() {
  const [questions, setQuestions] = useState([]);
  const check1 = useSelector((state1) => state1);
  const [timerSetting, setTimerSetting] = useState(null);
  const [handleReview, setHandleReview] = useState({});
  const [questionLength, setQuestionLength] = useState();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let data = Localstorage();

  let navigator = useNavigate();

  let Details = JSON.parse(localStorage.getItem("ownerDetails"));
  const timer = useLogout(60);
  if (timer === 0) {
    navigator(
      `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
    );
  }

  const inputChanged = (e, id) => {
    setHandleReview({ ...handleReview, [id]: e.target.value });
  };

  const { fetchQuestions } = Questionsapi();

  useEffect(() => {
    async function fetchData() {
      let response = await fetchQuestions();
      if (response.length !== 0) {
        setQuestionLength(response.length);
        let requiredResponse = response.filter(
          (value) => value.question_type === 2 && value.question != ""
        );
        setQuestions(requiredResponse);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("questionsData");
    if (data !== null) {
      setHandleReview(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    clearTimeout(timerSetting);
    const newTimer = setTimeout(() => {
      //fakeApi()
      localStorage.setItem("questionsData", JSON.stringify(handleReview));
    }, 500);

    setTimerSetting(newTimer);
  }, [handleReview]);

  if (check1.phone_number === "") {
    navigator("/user");
  }

  const handleClick = (data) => {
    if (Object.keys(handleReview).length !== questionLength) {
      alert("please answer all questions");
    } else {
      navigator("/complaint");
    }
  };

  const Back = () => {
    navigator("/question1");
  };

  const onSubmit = async (data) => {
    // if (questionLength === Object.keys(handleReview).length) {
    //   setLoading(true);
    //   let response = await Answersposting();
    //   if (response) {
    //     setLoading(false);
    //     navigator("/thankyou");
    //   }
    // } else {
    //   return alert("please answer all questions");
    // }
    let Details = JSON.parse(localStorage.getItem("ownerDetails"));
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/checkdistance/${Details.branchId}/${Details.departmentId}/${Details.lats}/${Details.long}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then(async (res) => {
        if (res.data.status == "success") {
          if (questionLength === Object.keys(handleReview).length) {
            setLoading(true);
            let response = await Answersposting();
            if (response) {
              setLoading(false);
              navigator("/thankyou");
            }
          } else {
            return alert("please answer all questions");
          }
        } else {
          alert(res.data.message);
        }
        // navigator("/language")
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 404") {
          localStorage.clear();
          alert("Department is Deleted");
          navigator(
            `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
          );
        } else {
          alert("please check your internet connection");
        }
      });
    // if (questionLength === Object.keys(handleReview).length) {
    //   let response = await Answersposting();
    //   if (response) {
    //     navigator("/thankyou");
    //   }
    // } else {
    //   return alert("please answer all questions");
    // }

    //    let data1=Object.values(data);

    //    if((data1[0] !='' && data1[1] !='') || (data1[0] !='' || data1[1] !=''))
    //    {
    //       const datafiltered = Object.fromEntries(
    //          Object.entries(data).filter(([key, value]) => value!=='') )

    //      var convert = Object.keys(datafiltered).map(function(key)
    //       {
    //       return {question:Number(key), answer:datafiltered[key]};
    //       });

    //     let storeData =[...check1.qObj.data,...convert];

    //      dispatch(Questions({data:storeData}))
    //      navigator('/thankyou')
    //    }
    // else
    //     {
    //       navigator('/thankyou')
    //     }
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
          }),
          content: (base) => ({
            ...base,
            transform: "translate(-50%, -50%)",
          }),
        }}
      >
        <div className="row questions container-fluid">
          <div className="offset-lg-1 col-lg-10  Qs_scroll">
            <div className="offset-lg-6 mt-4">
              <h3 className="head_dept"> {data.Department} </h3>
            </div>
            <div className="card_respo card_align surface_cardtop">
              <div className="card surface_card card1">
                <div className="card surface_card card2">
                  <div className="card surface_card card3">
                    <div className="body surface-body m-4 rating_screen">
                      <div>
                        <form autocomplete="off">
                          {questions.length > 0 ? (
                            questions.map((value, index) => (
                              <span>
                                <div>
                                  <p className="question_size">
                                    {index + 1}. {value.question}
                                  </p>
                                  <input
                                    type="text"
                                    className="text"
                                    value={
                                      handleReview
                                        ? handleReview[value.q_id]
                                        : ""
                                    }
                                    {...register(`${value.q_id}`)}
                                    onChange={(e) =>
                                      inputChanged(e, value.q_id)
                                    }
                                  />
                                </div>
                                <br />
                              </span>
                            ))
                          ) : (
                            <p>
                              No Questions to Display in the Selected Department
                            </p>
                          )}
                        </form>

                        <br />
                        <p className="text-center question_size">
                          {data.complaint}
                        </p>
                        <button
                          className="btn btn-info btn-lg text-white rise_btn"
                          onClick={handleSubmit(handleClick)}
                        >
                          {data.complaintraise}
                        </button>
                      </div>
                      <div className="row footer footer_direction direction_footer pb-4 pt-4">
                        <div className="col-sm-6 col-6 ">
                          <span onClick={Back}>{data.back}</span>
                        </div>
                        <div className=" col-sm-6 col-6 ">
                          <span onClick={handleSubmit(onSubmit)}>
                            {data.submit}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}
export default Questionscreen2;
