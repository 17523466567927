import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../adminsStyles/superdashboard.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import axios from "axios";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import trash from "../../../SuperAdmin/allSVGS/trash.png";
import editicon from "../../../SuperAdmin/allSVGS/edit.png";
import views from "../../../SuperAdmin/allSVGS/view.png";
import { Dropdown } from "react-bootstrap";
import { FaFileExcel } from "react-icons/fa";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import Swal from "sweetalert2";
import "../Logging/login.css";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toastMsg } from "../../../../helper";
import ReactPaginate from "react-paginate";
// import { toastmsg } from '../../../src/Helper';
import { toast } from "react-toastify";
import exportFromJSON from "export-from-json";
import { Spinner } from "react-bootstrap";
import adminimg from "../../../SuperAdmin/allSVGS/adminimg.png";
import LoadingOverlay from "react-loading-overlay";

const SuperHospital = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [page, setpage] = useState(0);
  const [pagecount, setPagecount] = useState(0);
  const [data, setData] = useState([]);
  const [viewShow, setViewShow] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [did, setDid] = useState("");
  const [change, setChange] = useState(0);
  const [hospital, setHospital] = useState();
  const [editImage, setEditImage] = useState("");
  const [view, setView] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [branchimg, setBranchimg] = useState("");
  const [imageselect, setImageSelect] = useState("");

  const [hospitalDetails, setHospitalDetails] = useState({
    name: "",
    address: "",
    mobileNumber: "",
    email: "",
    hospitalName: "",
  });

  const [editHospitalDetails, setEditHospitalDetails] = useState({
    a_id: "",
    name: "",
    address: "",
    mobileNumber: "",
    email: "",
    hospitalName: "",
    latitude: "",
    longitude: "",
    password: "",
  });

  const [formError, setFormError] = useState({});

  const handleShow = (x) => {
    reset();
    if (x.a_id >= 1) {
      setShow(true);
      setDid(x.a_id);
    } else {
      setShow(true);
    }
  };

  const handleShowPass = () => {
    let pwd = document.getElementById("passwordField");
    if (pwd.type === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleShowPass1 = () => {
    let pwd = document.getElementById("passwordField1");
    if (pwd.type === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleView = async (id) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}api/viewadmin/${id}`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        setHospitalDetails({
          ...hospitalDetails,
          name: res.data.data[0].name,
          address: res.data.data[0].address,
          mobileNumber: res.data.data[0].mobilenumber,
          email: res.data.data[0].email,
          hospitalName: res.data.data[0].company_name,
        });
        setViewShow(true);
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const pdfDownload = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/downloadadmindetails/${
          search === "" ? 0.001 : search
        }/pdf`,
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTH_KEY,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      )

      .then((response) => {
        if (!response) {
          throw new Error("Network response was not ok");
        }
        const url = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        // Extract the filename from the response headers or set a default name
        const filename = "admins.pdf";
        link.download = filename;
        // Simulate a click on the link to trigger the download
        link.click();
        // Clean up the temporary URL
        URL.revokeObjectURL(url);
      })

      .catch((error) => {
        // alert(error);
        toastMsg(error, toast.POSITION.TOP_CENTER, 2000);
      });
  };

  const handleClose = () => {
    setShow(false);
    setHospital({});
    setHospitalDetails({});
    setEditHospitalDetails({
      a_id: "",
      name: "",
      address: "",
      mobileNumber: "",
      email: "",
      hospitalName: "",
      latitude: "",
      longitude: "",
      password: "",
    });
    setFormError({});
    setViewShow(false);
    reset();
    setImageSelect("");
    setBranchimg("");
    setDid("");
    setView(false);
    setImageError(false);
    setLoading(false);
  };

  const uploadImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setBranchimg(e.target.files[0]);
      setImageSelect(URL.createObjectURL(e.target.files[0]));
      setImageError(false);
    }
  };

  const EditImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setEditImage(e.target.files[0]);
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const clickImg = (e) => {
    e.preventDefault();
    let hiddenFileInput = document.getElementById("hiddenFileInput");
    hiddenFileInput.click();
  };

  const clickImg1 = (e) => {
    e.preventDefault();
    let hiddenFileInput = document.getElementById("hiddenFileInput1");
    hiddenFileInput.click();
  };

  const download = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/downloadadmindetails/${
          search == "" ? 0.001 : search
        }/excel`,

        { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
      )
      .then((res) => {
        let data = res.data.data;
        if (data.length > 0) {
          exportFromJSON({
            data: data,
            fileName: `adminlist.xls`,
            exportType: exportFromJSON.types.xls,
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const [dimensions, setDimensions] = useState({
    latitude: "",
    longitude: "",
  });

  const geo = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      dimensions.latitude = position.coords.latitude;
      dimensions.longitude = position.coords.longitude;
      setDimensions({
        ...dimensions,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  };

  const handleInput = (e, id) => {
    const { name, value } = e.target;
    const newData = hospital.map((item) =>
      item.a_id === id && name ? { ...item, [name]: value } : item
    );
    reset();
  };

  const handleEditInput = (e) => {
    setEditHospitalDetails({
      ...editHospitalDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getadminlist();
    geo();
  }, [did, page, change]);

  const getadminlist = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}api/adminlist/${page}/${10}`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          setData(res.data.data);
          setPagecount(res.data.totalPages);
        } else {
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const handlePageClick = (event) => {
    setpage(event.selected);
  };

  const handleEdit = async (id) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}api/viewadmin/${id}`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        setEditHospitalDetails({
          ...editHospitalDetails,
          a_id: id,
          name: res.data.data[0].name,
          address: res.data.data[0].address,
          mobileNumber: res.data.data[0].mobilenumber,
          email: res.data.data[0].email,
          hospitalName: res.data.data[0].company_name,
          latitude: res.data.data[0].lat,
          longitude: res.data.data[0].long,
          // password: res.data.data[0].password,
        });

        if (
          res.data.data[0].branch_image !== "" &&
          res.data.data[0].branch_image != null
        ) {
          setEditImage(res.data.data[0].branch_image);
          setSelectedImage(
            `https://gradeusbu1dev.krify.com/api/branchimages/${res.data.data[0].branch_image}`
          );
          setLoading(false);
        } else {
          setSelectedImage("");
          setEditImage("");
        }
        // setSelectedImage();
        setView(true);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const validateForm = () => {
    let err = {};
    if (editHospitalDetails.hospitalName === "") {
      err.hospitalName = "Required field";
    }

    if (editHospitalDetails.name === "") {
      err.name = "Required field";
    }

    if (editHospitalDetails.mobileNumber === "") {
      err.mobileNumber = "Required field";
    } else {
      const mobileregex = /^[0-9+-]+$/;
      if (
        !mobileregex.test(
          editHospitalDetails.mobileNumber ||
            editHospitalDetails.mobileNumber.length > 6 ||
            editHospitalDetails.mobileNumber.length < 13
        )
      ) {
        err.mobileNumber =
          "Please enter valid mobile number must be at least 6 and  at most 12";
      }
    }

    if (editHospitalDetails.address === "") {
      err.address = "Required field";
    }

    if (editHospitalDetails.latitude === "") {
      err.latitude = "Required field";
      // } else {
      //   const latsregex = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
      //   if (!latsregex.test(editHospitalDetails.latitude)) {
      //     err.latitude = "please enter valid latitude";
      //   }
      // else{
      //     alert("")
      //   }
    }

    if (editHospitalDetails.longitude === "") {
      err.longitude = "Required field";
      // } else {
      //   const latsregex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
      //   if (!latsregex.test(editHospitalDetails.longitude)) {
      //     err.longitude = "please enter valid longitude";
      //   }
    }

    if (editHospitalDetails.email === "") {
      err.email = "Required field";
    } else {
      let regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.(com|in|org|net|ae|co)$/;
      if (!regex.test(editHospitalDetails.email)) {
        err.email = "Email not valid!";
      }
    }

    if (editHospitalDetails.password !== "") {
      let passRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passRegex.test(editHospitalDetails.password)) {
        err.password =
          "Your password is weak. Please use at least 8 characters with a mix of special characters, numbers, uppercase, and lowercase letters.";
      }
    }

    if (editImage === "" || editImage == null) {
      err.image = "Required field ";
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleEditSubmit = async () => {
    let isValid = validateForm();
    const payload = new FormData();
    payload.append("a_id", editHospitalDetails.a_id);
    payload.append("name", editHospitalDetails.name);
    payload.append("company_name", editHospitalDetails.hospitalName);
    payload.append("address", editHospitalDetails.address);
    payload.append("mobile", editHospitalDetails.mobileNumber);
    payload.append("email", editHospitalDetails.email);
    payload.append("password", editHospitalDetails.password);
    payload.append("lat", editHospitalDetails.latitude);
    payload.append("long", editHospitalDetails.longitude);
    editImage == null || editImage == ""
      ? payload.append("bimage", new Blob([adminimg]), "filename.jpg")
      : payload.append("bimage", editImage);

    if (isValid) {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}api/editadmin`, payload, {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        })
        .then((res) => {
          if (res.data.status === "Success") {
            setLoading(false);
            toastMsg(
              "Center updated successfully",
              toast.POSITION.TOP_CENTER,
              2000
            );
            setEditHospitalDetails({
              a_id: "",
              name: "",
              address: "",
              mobileNumber: "",
              email: "",
              hospitalName: "",
              latitude: "",
              longitude: "",
              password: "",
            });
            handleClose();
            getadminlist();
          } else {
            toastMsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
          }
        })
        .catch((error) => {
          // alert(error);
          toastMsg(error, toast.POSITION.TOP_CENTER, 2000);
        });
      setLoading(false);
    }
    // else{
    //   alert("Please Fill All fields")
    // }
  };

  const onSubmit = async (data) => {
    if (imageselect != "") {
      setLoading(true);
      const payload = new FormData();
      payload.append("a_id", did && did);
      payload.append("name", data.Name);
      payload.append("company_name", data.HospitalName);
      payload.append("address", data.Address);
      payload.append("mobile", data.MobileNumber);
      payload.append("email", data.Email);
      payload.append("password", data.Password);
      payload.append("lat", data.lats);
      payload.append("long", data.long);
      payload.append("bimage", branchimg && branchimg);
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}api/addadmin`, payload, {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        })
        .then((res) => {
          if (res.data.status === "success") {
            toastMsg(
              "Center added Succesfully",
              toast.POSITION.TOP_CENTER,
              2000
            );
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}api/emailverification`,
                {
                  admin_id: res.data.admin_id,
                  email: data.Email,
                },
                { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
              )
              .then((res) => {
                if (res.data.status == "success") {
                  toastMsg(res.data.mesage, toast.POSITION.TOP_CENTER, 2000);
                  reset();
                  setShow(false);
                  setImageSelect("");
                  setBranchimg("");
                  getadminlist();
                  setLoading(false);
                } else {
                  toastMsg(res.data.mesage, toast.POSITION.TOP_CENTER, 2000);
                  setLoading(false);
                }
              });
          } else {
            toastMsg(res.data.message, toast.POSITION.TOP_CENTER, 3000);
          }
        })
        .catch((error) => {
          // alert(error);
          toastMsg(error.message, toast.POSITION.TOP_CENTER, 3000);
          setLoading(false);
        });
    } else {
      setImageError(true);
    }
  };
  let limit = 5;
  const handleSearch = async (e) => {
    // alert(e.target.value)
    setSearch(e.target.value);
    let search = e.target.value === "" ? 0.001 : e.target.value;
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/adminsearch/${page}/${10}/${
          e.target.value === "" ? 0.001 : e.target.value
        }`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          // toastMsg(res.data.message, toast.POSITION.TOP_CENTER, 3000);
          setData(res.data.data);
          setPagecount(res.data.totalPages);
        }
      })
      .catch((error) => {
        // console.log(error, "error");
        toastMsg(error.message, toast.POSITION.TOP_CENTER, 3000);
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#000000",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      setLoading(true);
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}api/deleteadmin/${id}`,
          headers: {
            Authorization: process.env.REACT_APP_AUTH_KEY,
          },
        })
          .then((response) => {
            setLoading(false);
            toastMsg(
              "Center Deleted Succesfully",
              toast.POSITION.TOP_CENTER,
              2000
            );
            setChange(change + 1);
            getadminlist();
          })
          .catch((error) => {
            alert(error.response.data.details[0].message);
            //return error;
          });
      } else if (result.isDismissed) {
        setLoading(false);
        // Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  // console.log(selectedImage, "xxx");
  return (
    <div>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
          content: (base) => ({
            ...base,
            transform: "translate(-50%, -50%)",
          }),
        }}
      >
        <div>
          <div className=" mx-2">
            <div className="card db-sty">
              <div className=" card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="sd-title">Centers</h5>
                  <div className="Add-admins">
                    <div className="dropdown_sty">
                      <Dropdown>
                        <Dropdown.Toggle
                          className="d-flex exportitems"
                          variant="white"
                          id="dropdown-basic"
                        >
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={pdfDownload}>
                            {" "}
                            <BsFillFileEarmarkPdfFill className="pdf_icon" />
                            PDF
                          </Dropdown.Item>
                          <Dropdown.Item onClick={download}>
                            <FaFileExcel className="excel_icon" />
                            EXCEL
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="search_btn">
                      <Form>
                        <Form.Group className="form_head">
                          <Form.Control
                            className=" input-width"
                            value={search}
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                            placeholder="Search Branch"
                          />
                          <i
                            class="fa fa-search search_icon"
                            aria-hidden="true"
                          ></i>
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="add-modal">
                      <Button size="sm" onClick={handleShow}>
                        Add Center
                      </Button>
                    </div>
                  </div>
                </div>

                <table className="table table-bordered table_text hospitalTable">
                  <tr>
                    <th>S.NO</th>
                    <th>Center Name</th>
                    <th>Admin Name</th>
                    <th>Email Id </th>
                    <th>Mobile Number</th>
                    <th>Actions</th>
                  </tr>
                  <tbody className="table_body">
                    {data && data.length > 0 ? (
                      data.map((values, index) => {
                        return (
                          <tr key={values.a_id}>
                            <td>{page * 10 + index + 1}</td>
                            <td> {values.company_name}</td>
                            <td> {values.name}</td>
                            <td>{values.email}</td>
                            <td> {values.mobilenumber}</td>
                            <td>
                              <img
                                alt="viewIcon"
                                className="icons_sty"
                                id="view"
                                onClick={() => {
                                  handleView(values.a_id);
                                }}
                                src={views}
                              />
                              <img
                                alt="EditIcon"
                                className="icons_sty"
                                onClick={() => handleEdit(values.a_id)}
                                src={editicon}
                              />
                              <img
                                alt="deleteicon"
                                className="icons_sty"
                                onClick={() => handleDelete(values.a_id)}
                                src={trash}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8}>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <br />
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={pagecount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>

          <Modal show={show} onHide={handleClose} size="lg" animation={true}>
            <Modal.Header closeButton className="closeButtons"></Modal.Header>
            <Modal.Title className="modal_titles">Add Center</Modal.Title>
            <Modal.Body>
              <form autoComplete="off">
                <Form.Group>
                  <div className="row ">
                    <div className="col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-4 ">
                          <Form.Label className="label-sty">
                            Center Name
                          </Form.Label>
                        </div>
                        <div className="col-md-8 ">
                          <Form.Control
                            name="company_name"
                            onChange={(e) => handleInput(e, hospital?.a_id)}
                            defaultValue={
                              hospital?.length != 0
                                ? hospital?.company_name
                                : ""
                            }
                            {...register("HospitalName", { required: true })}
                            readOnly={did != "" ? true : false}
                          />
                          {errors.HospitalName &&
                            errors.HospitalName.type === "required" && (
                              <p className="error mt-0">Required field</p>
                            )}
                          {errors.HospitalName &&
                            errors.HospitalName.type === "pattern" && (
                              <p className="error mt-0">
                                please enter valid Email
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <Form.Label className="label-sty">Name</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            name="name"
                            onChange={(e) => handleInput(e, hospital?.a_id)}
                            defaultValue={
                              hospital?.length != 0 ? hospital?.name : ""
                            }
                            {...register("Name", { required: true })}
                            readOnly={did != "" ? true : false}
                          />
                          {errors.Name && errors.Name.type === "required" && (
                            <p className="error mt-0">Required field</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row ">
                    <div className="col-md-6 col-12">
                      <div className="row  align-items-center">
                        <div className="col-md-4">
                          <Form.Label className="label-sty">
                            Mobile Number
                          </Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            type="tel"
                            name="mobilenumber"
                            onChange={(e) => handleInput(e, hospital?.a_id)}
                            defaultValue={
                              hospital?.length != 0
                                ? hospital?.mobilenumber
                                : ""
                            }
                            {...register("MobileNumber", {
                              required: true,
                              pattern: /^[0-9+-]+$/,
                              minLength: 6,
                              maxLength: 12,
                            })}
                            readOnly={did != "" ? true : false}
                          />
                          {errors.MobileNumber &&
                            errors.MobileNumber.type === "required" && (
                              <p className="error mt-0">Required field</p>
                            )}
                          {errors.MobileNumber &&
                            errors.MobileNumber.type === "pattern" && (
                              <p className="error mt-0">
                                please enter valid Mobile Number
                              </p>
                            )}
                          {errors.MobileNumber &&
                            errors.MobileNumber?.type === "minLength" && (
                              <p className="error mt-0">must be at least 6</p>
                            )}
                          {errors.MobileNumber &&
                            errors.MobileNumber?.type === "maxLength" && (
                              <p className="error mt-0">must be at most 12</p>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className=" col-md-3">
                          <Form.Label className="label-sty">Email</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            type="text"
                            name="email"
                            onChange={(e) => handleInput(e, hospital?.a_id)}
                            defaultValue={
                              hospital?.length != 0 ? hospital?.email : ""
                            }
                            {...register("Email", {
                              required: true,
                              pattern:
                                /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
                            })}
                            readOnly={did != "" ? true : false}
                          />
                          {errors.Email && errors.Email.type === "required" && (
                            <p className="error mt-0">Required field</p>
                          )}
                          {errors.Email && errors.Email.type === "pattern" && (
                            <p className="error mt-0">
                              please enter valid Email
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    {did === "" ? (
                      <div className="d-flex col-md-6 col-12">
                        <div className="row align-items-center">
                          <div className="col-md-4">
                            <Form.Label className="label-sty">
                              Set Password
                            </Form.Label>
                          </div>
                          <div
                            className="col-md-8 pass_input"
                            style={{ marginTop: "13px" }}
                          >
                            <Form.Control
                              id="passwordField"
                              className="brdr_clr"
                              type={passwordType}
                              name="password"
                              onChange={(e) => handleInput(e, hospital?.a_id)}
                              defaultValue={
                                hospital?.length !== 0 ? hospital?.password : ""
                              }
                              {...register("Password", {
                                required: true,
                                pattern:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                minLength: 8,
                              })}
                              readOnly={did != "" ? true : false}
                            />
                            <span
                              type="button"
                              onClick={handleShowPass}
                              className="spanHos"
                            >
                              {" "}
                              {passwordType === "password" ? (
                                <FaEyeSlash className="fa_eye" />
                              ) : (
                                <FaEye className="fa_eye" />
                              )}
                            </span>
                            {errors.Password &&
                              errors.Password.type === "required" && (
                                <span className="error mt-0">
                                  Required field
                                </span>
                              )}
                            {errors.Password &&
                              errors.Password.type === "pattern" && (
                                <span className="error mt-0">
                                  Your password is weak. Please use at least 8
                                  characters with a mix of special characters,
                                  numbers, uppercase, and lowercase letters.
                                </span>
                              )}
                            {errors.Password &&
                              errors.Password.type === "minLength" && (
                                <span className="error mt-0">
                                  Your password is weak. Please use at least 8
                                  characters with a mix of special characters,
                                  numbers, uppercase, and lowercase letters.
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="label-sty col-md-3">
                          <Form.Label className="label-sty">Address</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            className="textAreascroll"
                            name="address"
                            onChange={(e) => handleInput(e, hospital?.a_id)}
                            as="textarea"
                            defaultValue={
                              hospital?.length != 0 ? hospital?.address : ""
                            }
                            rows={2}
                            {...register("Address", { required: true })}
                          />
                          {errors.Address &&
                            errors.Address.type === "required" && (
                              <p className="error mt-0">Required field</p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-4 ">
                          <Form.Label className="label-sty">
                            latitude
                          </Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            {...register("lats", {
                              required: true,
                              pattern: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
                            })}
                            readOnly={did != "" ? true : false}
                            className="brder_align"
                          />
                          {errors.lats && errors.lats.type === "required" && (
                            <p className="error mt-0">Required field</p>
                          )}
                          {errors.lats && errors.lats.type === "pattern" && (
                            <p className="error mt-0">
                              Please enter valid Latitude
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <Form.Label className="label-sty">
                            longitude
                          </Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            {...register("long", {
                              required: true,
                              pattern: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
                            })}
                            readOnly={did != "" ? true : false}
                            className="brder_align"
                          />
                          {errors.long && errors.long.type === "required" && (
                            <p className="error mt-0">Required field</p>
                          )}
                          {errors.long && errors.long.type === "pattern" && (
                            <p className="error mt-0">
                              Please enter valid Longitude
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6  row justify-content-between align-items-center">
                      <div className="col-md-6 mt-4 ">
                        <div>
                          <input
                            type="file"
                            id="hiddenFileInput"
                            accept="image/*"
                            onChange={(e) => uploadImage(e)}
                            style={{ display: "none" }}
                            name="hsImage"
                          />
                        </div>
                        <div>
                          <img
                            alt=""
                            src={imageselect != "" ? imageselect : adminimg}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mt-1">
                        <div>
                          <button
                            className="upload_sty"
                            onClick={(e) => clickImg(e)}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8  d-flex justify-content-center">
                      {imageError && (
                        <p className="error mt-0">Please upload image </p>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </form>
            </Modal.Body>

            <Modal.Footer className="modal_footer2">
              {did != "" ? (
                ""
              ) : (
                <button
                  className="add_btn"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Add
                </button>
              )}
            </Modal.Footer>
          </Modal>

          <Modal
            show={viewShow}
            onHide={handleClose}
            size="lg"
            animation={true}
          >
            <Modal.Header closeButton className="closeButtons"></Modal.Header>
            <Modal.Title className="modal_titles">View Center</Modal.Title>
            <Modal.Body>
              <form autoComplete="off">
                <Form.Group>
                  <div className="row ">
                    <div className="col-md-6 d-flex">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <Form.Label className="label-sty">
                            Center Name
                          </Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            defaultValue={
                              hospitalDetails?.length != 0
                                ? hospitalDetails?.hospitalName
                                : ""
                            }
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <Form.Label className="label-sty">Name</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            defaultValue={
                              hospitalDetails?.length != 0
                                ? hospitalDetails?.name
                                : ""
                            }
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row ">
                    <div className="col-md-6">
                      <div className="row  align-items-center">
                        <div className="col-md-4">
                          <Form.Label className="label-sty">
                            Mobile Number
                          </Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            defaultValue={
                              hospitalDetails?.length != 0
                                ? hospitalDetails?.mobileNumber
                                : ""
                            }
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6  ">
                      <div className="row align-items-center">
                        <div className=" label-sty col-md-4">
                          <Form.Label>Email</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            defaultValue={
                              hospitalDetails?.length != 0
                                ? hospitalDetails?.email
                                : ""
                            }
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6  ">
                      <div className="row align-items-center">
                        <div className="label-sty col-md-4">
                          <Form.Label>Address</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            defaultValue={
                              hospitalDetails?.length != 0
                                ? hospitalDetails?.address
                                : ""
                            }
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </Form.Group>
              </form>
            </Modal.Body>
          </Modal>

          <Modal show={view} onHide={handleClose} size="lg" animation={true}>
            <Modal.Header closeButton className="closeButtons"></Modal.Header>
            <Modal.Title className="modal_titles">Edit center</Modal.Title>
            <Modal.Body>
              <form autoComplete="off">
                <Form.Group>
                  <div className="row ">
                    <div className="col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <Form.Label className="label-sty">
                            Center Name
                          </Form.Label>
                        </div>
                        <div className="col-md-8 ">
                          <Form.Control
                            name="hospitalName"
                            onChange={(e) => handleEditInput(e)}
                            defaultValue={editHospitalDetails?.hospitalName}
                          />
                          <p className="error mt-0">{formError.hospitalName}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <Form.Label className="label-sty">Name</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            name="name"
                            onChange={(e) => handleEditInput(e)}
                            defaultValue={
                              Object.keys(editHospitalDetails)?.length != 0
                                ? editHospitalDetails?.name
                                : ""
                            }
                          />
                          <p className="error mt-0">{formError.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row ">
                    <div className="col-md-6 col-12">
                      <div className="row  align-items-center">
                        <div className="col-md-4">
                          <Form.Label className="label-sty">
                            Mobile Number
                          </Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            name="mobileNumber"
                            onChange={(e) => handleEditInput(e)}
                            defaultValue={
                              Object.keys(editHospitalDetails)?.length != 0
                                ? editHospitalDetails?.mobileNumber
                                : ""
                            }
                          />
                          <p className="error mt-0">{formError.mobileNumber}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className=" col-md-3">
                          <Form.Label className="label-sty">Email</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            type="text"
                            name="email"
                            onChange={(e) => handleEditInput(e)}
                            defaultValue={
                              Object.keys(editHospitalDetails)?.length != 0
                                ? editHospitalDetails?.email
                                : ""
                            }
                          />
                          <p className="error mt-0">{formError.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="d-flex col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <Form.Label className="label-sty">
                            New Password
                          </Form.Label>
                        </div>
                        <div
                          className="col-md-8 pass_input"
                          style={{ marginTop: "13px" }}
                        >
                          <Form.Control
                            id="passwordField1"
                            className="brdr_clr"
                            type={passwordType}
                            name="password"
                            onChange={(e) => handleEditInput(e)}
                            defaultValue={
                              Object.keys(editHospitalDetails)?.length !== 0
                                ? editHospitalDetails?.password
                                : ""
                            }
                          />
                          <span
                            type="button"
                            onClick={handleShowPass1}
                            className="spanHos"
                          >
                            {" "}
                            {passwordType === "password" ? (
                              <FaEyeSlash className="fa_eye" />
                            ) : (
                              <FaEye className="fa_eye" />
                            )}
                          </span>
                          <p className="error mt-0">{formError.password}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="label-sty col-md-3">
                          <Form.Label className="label-sty">Address</Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            className="textAreascroll"
                            name="address"
                            onChange={(e) => handleEditInput(e)}
                            as="textarea"
                            defaultValue={
                              Object.keys(editHospitalDetails)?.length != 0
                                ? editHospitalDetails?.address
                                : ""
                            }
                            rows={2}
                          />
                          <p className="error mt-0">{formError.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <Form.Label className="label-sty">
                            latitude
                          </Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            name="latitude"
                            onChange={(e) => handleEditInput(e)}
                            defaultValue={editHospitalDetails.latitude}
                            className="brder_align"
                          />
                          <p className="error mt-0">{formError.latitude}</p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <Form.Label className="label-sty">
                            longitude
                          </Form.Label>
                        </div>
                        <div className="col-md-8">
                          <Form.Control
                            name="longitude"
                            onChange={(e) => handleEditInput(e)}
                            defaultValue={editHospitalDetails.longitude}
                            className="brder_align"
                          />
                          <p className="error mt-0">{formError.longitude}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6  row justify-content-between align-items-center">
                      <div className="col-md-6 mt-4 ">
                        <div>
                          <input
                            type="file"
                            id="hiddenFileInput1"
                            accept="image/*"
                            onChange={(e) => EditImage(e)}
                            style={{ display: "none" }}
                            name="hsImage"
                          />
                        </div>
                        <div>
                          <img
                            alt="branchImage"
                            src={
                              selectedImage !== "" ? selectedImage : adminimg
                            }
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <p className="error mt-0">{formError.image}</p>
                      </div>
                      <div className="col-md-4 mt-1">
                        <div>
                          <button
                            className="upload_sty"
                            onClick={(e) => clickImg1(e)}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </form>
            </Modal.Body>

            <Modal.Footer className="modal_footer2">
              <button
                className="add_btn"
                type="submit"
                onClick={handleEditSubmit}
              >
                Edit
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default SuperHospital;
