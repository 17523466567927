import React from "react";
import "./complaint.css";
import Localstorage from "../utils/Localstorage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Answersposting from "../../APIS/Answersposting";
import { useSelector, useDispatch } from "react-redux";
import { Userid } from "../../Redux/Actioncreator";
import axios from "axios";
import useLogout from "../utils/Idle";
import LoadingOverlay from "react-loading-overlay";

function Complaint() {
  let [complaint, setComplaint] = useState("");
  const [loading, setLoading] = useState(true);
  let selecteddepartment = JSON.parse(
    localStorage.getItem("selecteddepartment")
  );
  let Details = JSON.parse(localStorage.getItem("ownerDetails"));

  let Answers = useSelector((state) => state);
  let dispatch = useDispatch();
  let data = Localstorage();
  let navigator = useNavigate();

  const timer = useLogout(60);
  if (timer === 0) {
    navigator(
      `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
    );
  }

  useEffect(() => {
    async function fetchData() {
      let response = await Answersposting(Answers);
      setLoading(false);
      dispatch(Userid(response.data.user_id));
    }
    fetchData();
  }, []);

  const Next = async () => {
    let Details = JSON.parse(localStorage.getItem("ownerDetails"));
    const user = JSON.parse(localStorage.getItem("userDetails"));
    const owner = JSON.parse(localStorage.getItem("ownerDetails"));
    const questionsData = JSON.parse(localStorage.getItem("questionsData"));

    Object.keys(Details).length > 0
      ? await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}api/checkdistance/${Details.branchId}/${Details.departmentId}/${Details.lats}/${Details.long}`,
            {
              headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
            }
          )
          .then((res) => {
            if (res.data.status == "success") {
              if (complaint !== "") {
                axios
                  .post(
                    `${process.env.REACT_APP_BASE_URL}api/complaints`,
                    {
                      complaint_msg: complaint,
                      user_id: Answers.user_id,
                      email: user.email,
                      dept_id: owner.departmentId,
                      read_status: 0,
                    },
                    {
                      headers: {
                        Authorization: process.env.REACT_APP_AUTH_KEY,
                      },
                    }
                  )
                  .then((res) => {
                    navigator("/thankyou");
                  });
              }
            } else {
              alert(res.data.message);
            }
            // navigator("/language")
          })
          .catch((err) => {
            alert(err.message);
          })
      : alert("Session expired! please scan the Qr Code");

   
    // if (Object.keys(Details).length > 0) {
    //   await axios
    //     .get(
    //       `${process.env.REACT_APP_BASE_URL}api/checkdistance/${Details.branchId}/${Details.departmentId}/${Details.lats}/${Details.long}`,
    //       {
    //         headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
    //       }
    //     )
    //     .then((res) => {
    //       if (res.data.status == "success") {
    //         if (complaint !== "") {
    //           axios
    //             .post(
    //               `${process.env.REACT_APP_BASE_URL}api/complaints`,
    //               {
    //                 complaint_msg: complaint,
    //                 user_id: Answers.user_id,
    //                 email: user.email,
    //                 dept_id: owner.departmentId,
    //                 read_status: 0,
    //               },
    //               {
    //                 headers: {
    //                   Authorization: process.env.REACT_APP_AUTH_KEY,
    //                 },
    //               }
    //             )
    //             .then((res) => {
    //               navigator("/thankyou");
    //             });
    //         }
    //       } else {
    //         alert(res.data.message);
    //       }
    //       // navigator("/language")
    //     })
    //     .catch((err) => {
    //       alert(err.message);
    //     });
    // } else alert("Session expired! please scan the Qr Code");

    // if (complaint !== "") {
    //   setLoading(true);
    //   axios
    //     .post(
    //       `${process.env.REACT_APP_BASE_URL}api/complaints`,
    //       {
    //         complaint_msg: complaint,
    //         user_id: Answers.user_id,
    //         email: user.email,
    //         dept_id: owner.departmentId,
    //         read_status: 0,
    //       },
    //       { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
    //     )
    //     .then((res) => {
    //       setLoading(false);
    //       navigator("/thankyou");
    //     });
    // }
    // if (Object.keys(Details).length > 0) {
    //   await axios
    //     .get(
    //       `${process.env.REACT_APP_BASE_URL}api/checkdistance/${Details.branchId}/${Details.departmentId}/${Details.lats}/${Details.long}`,
    //       {
    //         headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
    //       }
    //     )
    //     .then((res) => {
    //       if (res.data.status === "success") {
    //         if (complaint !== "") {
    //           setLoading(true);
    //           axios
    //             .post(
    //               `${process.env.REACT_APP_BASE_URL}api/complaints`,
    //               {
    //                 complaint_msg: complaint,
    //                 user_id: Answers.user_id,
    //                 email: user.email,
    //                 dept_id: owner.departmentId,
    //                 read_status: 0,
    //               },
    //               { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
    //             )
    //             .then((res) => {
    //               setLoading(false);
    //               navigator("/thankyou");
    //             });
    //         }
    //       } else {
    //         alert(res.data.message);
    //       }
    //       // navigator("/language")
    //     })
    //     .catch((err) => {
    //       alert(err.message);
    //     });
    // } else {
    //   alert("Session expired! please scan the Qr Code");
    // }
  };

  const Next1 = () => {
    navigator("/thankyou");
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
          }),
          content: (base) => ({
            ...base,
            transform: "translate(-50%, -50%)",
          }),
        }}
      >
        <div className="row complaint container-fluid">
          <div className="offset-lg-1 col-lg-10">
            <div className="offset-lg-6 mt-5 complaint_title">
              <h3 className="comp_dept"> {data.Department} </h3>
            </div>
            <div className="card_responsive ">
              <div className="card card1">
                <div className="card card2">
                  <div className="card card3">
                    <div className=" mt-4">
                      <h4 className=" header text-center">
                        {data.Complaintform}
                      </h4>
                    </div>
                    <br />
                    <div className="body text-center">
                      <textarea
                        className="textareas"
                        rows="8"
                        cols="80"
                        placeholder={data.complaintarea}
                        value={complaint}
                        onChange={(e) => setComplaint(e.target.value)}
                      ></textarea>
                    </div>
                    <br />
                    <br />
                    <div className="row footer footer_direction pb-4">
                      <div className="col-sm-6 col-6">
                        <span onClick={Next1}>{data.skip}</span>
                      </div>
                      <div className="col-sm-6 col-6">
                        <span onClick={Next}>{data.submit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Complaint;
