import React, { useState, useEffect } from "react";
import Admin from "../../allSVGS/admin.svg";
import "../Header/header.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import profilePic from "../../allSVGS/profilepic.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { toastMsg } from "../../../../helper";

export default function Header() {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [avatar, setAvatar] = useState("");
  const [profileimg, setProfileImg] = useState("");
  // const profileImage = localStorage.getItem("profile")
  // console.log(profileImage,"profile")

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/supadprofile`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        setProfileImg(
          `${process.env.REACT_APP_BASE_URL}api/superadminprofile/${res.data.profile}`
        );
      })
      .catch((e) => {
        console.log(e.messege);
      });
  }, []);

  const handleFileSelect = async (event) => {
    setSelectedFile(event.target.files[0]);
    const file = event.target.files[0]; // this Object holds a reference to the file on disk
    const data_url = URL.createObjectURL(event.target.files[0]);
    setProfileImg(data_url);
    // return false
    const formDataa = new FormData();
    formDataa.append("image", event.target.files[0]);
    formDataa.append("spa_id", 1);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}api/editprofile`, formDataa, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        console.log(res.data, "data");
        if (res) {
          console.log(res.data, "data");
          toastMsg(
            "Profile added Succesfully",
            toast.POSITION.TOP_CENTER,
            2000
          );
          setAvatar();
          console.log(profileimg, "image");
          // console.log(res.data.message);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // upload profile avatar cancel
  const handleCancel = () => {
    setShow(false);
    setAvatar("");
  };

  const stylgin = {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  };
  const signin = {
    color: "#000000",
    fontWeight: "400",
    fontSize: "14px",
    top: "20",
    marginBottom: 0,
    marginRight: "2px",
  };
  return (
    <div>
      <ToastContainer closeButton={false} hideProgressBar={true} />
      <header className="container">
        <div style={stylgin}>
          <p style={signin}>Super Admin </p>
          <img
            alt=""
            onClick={handleShow}
            src={profileimg}
            style={{ height: "40px", width: "40px", borderRadius: "20px" }}
          />
          <Modal
            className="modal-data "
            show={show}
            size="sm"
            onHide={handleClose}
            animation={false}
          >
            <Modal.Body className="itemDirection-col">
              {/* <img src={profilePic}/> */}
              <div className="d-flex justify-content-center mb-3">
                {profileimg ? (
                  <img
                    src={profileimg}
                    alt="Avatar"
                    style={{
                      borderRadius: "50%",
                      width: "120px",
                      height: "120px",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: "#ccc",
                      borderRadius: "50%",
                      width: "120px",
                      height: "120px",
                    }}
                  ></div>
                )}
              </div>
              <div className="align-items-center ">
                <div className="upload-btn-wrapper">
                  <button className="btn_upload">Upload</button>
                  <input
                    type="file"
                    name="myfile"
                    onChange={handleFileSelect}
                  />
                </div>
              </div>
              <div className="align-items-center">
                <button className="btn_upload" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </header>
    </div>
  );
}
